@import "../../../assets/scss/global.scss";
@import "../../../assets/scss/mixin.scss";

.layout-content-article {
  .content {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .single-pushed-content.ant-layout {
    margin-bottom: 40px;
    .ant-col {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .right {
      flex-direction: row-reverse;
    }
    
  }
}

@include dark {
  .single-pushed-content.ant-layout {
    .ant-row{
      background-color: #1f1f1f;
    }
     
  }
}


