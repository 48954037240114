@import '../../../assets/scss/global.scss';
@import '../../../assets/scss/mixin.scss';

.ant-drawer-body {
  padding: 0;
}
.card-drawer {
  &-large {
    width: 100%;
    min-height: 40px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    &-text {
      width: 100%;
      font-weight: 500;
      min-height: 40px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.headset-card {
  .ant-card-body {
    padding: 0;
    margin: 0;
    .headset-card-details {
      &-title {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        background-color: #f9f9f9;
        @include md {
          padding: 0 30px !important;
        }
        & a {
          color: inherit;
          text-decoration: underline;
        }
        &-smile {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          right: 5%;
          font-size: 24px;
          .better {
            svg {
              path {
                fill: green;
              }
            }
          }
          .worst {
            svg {
              path {
                fill: red;
              }
            }
          }
        }
      }
      &-normal {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        @include md {
          width: 100%;
        }
        &-text {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          @include md {
            width: 100%;
            font-weight: 600;
          }
          a {
            color: inherit;
            text-decoration: underline;
          }
        }
      }
      &-large {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        &.description {
          padding: 12px;
          text-align: justify;
        }
        @include md {
          padding: 5px !important;
        }
        &-text {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
        }
      }
      &-logo {
        font-size: 20px;
        margin-right: 10px;
      }
      div {
        min-height: 40px;
        padding: 0px;
        text-align: center;
      }
    }
  }
}

@include dark {
  .button-standalone {
    background-color: $dark-main-hover;
    border-color: $dark-main-hover;
    &:active {
      background-color: $dark-main-hover;
      border-color: $dark-main-hover;
      color: $white-text;
    }
    &:focus {
      background-color: $dark-main-hover;
      border-color: $dark-main-hover;
      color: $white-text;
    }
  }
  .headset-card {
    .ant-card-body {
      .headset-card-details {
        &-title {
          background-color: $dark-main-hover;
          & a {
            color: $white-text;
          }
          &-smile {
            svg {
              border-radius: 30px;
              path {
                fill: white;
              }
            }
            .better {
              svg {
                background: green;
              }
            }
            .worst {
              svg {
                background: red;
              }
            }
          }
        }
      }
    }
  }
}
