@import '../../../assets/scss/global.scss';
@import '../../../assets/scss/mixin.scss';

.headset-card {
  position: relative;

  .ant-card {
    text-align: center;
    border-radius: 20px;
    border-color: #d9d9d9;
    &-meta-description{
      padding:0px 10px 0px 10px;
      min-height: 70px;
    }
    &-head{
      padding:0;
      &-title{
        @include xs{
          font-size: 14px;
        }
      }
    }
    @include sm {
      .ant-card-body {
        padding: 0;

        .headset-card-body-summary {
          margin: 24px;
        }
      }
    }

    &.selected {
      box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.32), 0 3px 6px 0 rgba(0, 0, 0, 0.24),
        0 5px 12px 4px rgba(0, 0, 0, 0.18);
    }
    .ant-image {
      padding: 18px;
      img {
        height: 200px;
        object-fit: contain;
      }
    }
  }
  .card-link {
    @include sm {
      display: none;
    }
    color: inherit;
    position: absolute;
    top: 60px;
    right: 0;
    padding: 10px;
    transition: color 0.7s ease;
    &:hover {
      color: $light-main-hover;
    }
  }
}

@include dark {
  .headset-card {
    .ant-card {
      border-color: #303030;
      &.selected,
      &:hover {
        box-shadow: 0 1px 2px -2px rgba(80, 61, 77, 0.32), 0 3px 6px 0 rgba(80, 61, 77, 0.24),
          0 5px 12px 4px rgba(80, 61, 77, 0.18);
        border: 1px solid rgb(80, 61, 77);
      }
    }
    .card-link {
      &:hover {
        color: $dark-main-link;
      }
    }
  }
}
