// Extra Extra Small devices
@mixin xxs {
  @media (max-width: #{$breakpoint-xxs}) {
      @content;
  }
}

// Extra Small devices
@mixin xs {
  @media (max-width: #{$breakpoint-xs}) {
      @content;
  }
}

// Small devices
@mixin sm {
  @media (max-width: #{$breakpoint-sm}) {
      @content;
  }
}

// Medium devices
@mixin md {
  @media (max-width: #{$breakpoint-md}) {
      @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: #{$breakpoint-lg}) {
      @content;
  }
}

// Extra Large devices
@mixin xl {
  @media (min-width: #{$breakpoint-xl}) {
      @content;
  }
}

// Extra Extra Large devices
@mixin xxl {
  @media (min-width: #{$breakpoint-xxl}) {
      @content;
  }
}

// apply for theme
@mixin dark {
  body.dark {
    @content;
  }
}