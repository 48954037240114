@import '../../../assets/scss/global.scss';
@import '../../../assets/scss/mixin.scss';

@keyframes bounce {
  20%,
  100% {
    top: -12px;
    text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc, 0 5px 0 #ccc,
      0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent, 0 9px 0 transparent,
      0 10px 10px rgba(0, 0, 0, 0.4);
  }
  0% {
    top: -12px;
  }
  10% {
    top: -32px;
    text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc, 0 5px 0 #ccc, 0 6px 0 #ccc,
      0 7px 0 #ccc, 0 8px 0 #ccc, 0 9px 0 #ccc, 0 50px 25px rgba(0, 0, 0, 0.2);
  }
}

@keyframes bounce-black {
  20%,
  100% {
    top: -12px;
    text-shadow: 0 1px 0 #303030, 0 2px 0 #303030, 0 3px 0 #303030, 0 4px 0 #303030, 0 5px 0 #303030,
      0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent, 0 9px 0 transparent,
      0 10px 10px rgba(0, 0, 0, 0.4);
  }
  0% {
    top: -12px;
  }
  10% {
    top: -32px;
    text-shadow: 0 1px 0 #303030, 0 2px 0 #303030, 0 3px 0 #303030, 0 4px 0 #303030, 0 5px 0 #303030,
      0 6px 0 #303030, 0 7px 0 #303030, 0 8px 0 #303030, 0 9px 0 #303030,
      0 50px 25px rgba(0, 0, 0, 0.2);
  }
}

@keyframes xAxis {
  0% {
    animation-timing-function: cubic-bezier(0.56, 0.58, 0, 1.05);
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes yAxis {
  0% {
    animation-timing-function: ease;
    transform: translateX(-50px);
  }
  85% {
    transform: translateX(5px);
  }
  90% {
    transform: translateX(0px);
  }
  95% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes xAxis-t {
  0% {
    animation-timing-function: cubic-bezier(0.56, 0.58, 0, 1.05);
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes yAxis-t {
  0% {
    animation-timing-function: ease;
    transform: translateX(50px);
  }
  85% {
    transform: translateX(-5px);
  }
  90% {
    transform: translateX(0px);
  }
  95% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0px);
  }
}

@include dark {
  .ant-row {
    &.auto-complete-wrapper {
      .ant-col {
        &.vs-animation {
          div {
            span {
              animation: bounce-black 5s ease infinite;
              color: $dark-main-hover;
              text-shadow: 0 1px 0 #303030, 0 2px 0 #303030, 0 3px 0 #303030, 0 4px 0 #303030,
                0 5px 0 #303030, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
                0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
            }
          }
        }
      }
    }
  }
}

.filters-divider{
  @include lg {
    display: none !important;
  }
}

.grid-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 16px;

  @media screen and (max-width: 1300px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @include lg {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @include md {
    grid-template-columns: 1fr 1fr;
  }
}

.grid-container-compare {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
}

.ant-divider-horizontal.ant-divider-with-text {
  &::before,
  &::after {
    border-top: 1px solid rgba(0, 0, 0, 0.06);
  }
}
.ant-row {
  &.auto-complete-wrapper {
    .ant-col {
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
      &.vs-animation {
        width: 12.5%;
        height: 80px;
        z-index: 1;
        @include md {
          width: 100%;
        }
        cursor: default;
        display: block;
        text-align: center;
        div {
          content: '';
          display: inline-block;
          &.compare-mode {
            &:first-child {
              animation: yAxis 1s;
              span {
                animation: xAxis 1s;
              }
            }
            &:last-child {
              animation: yAxis-t 1s;
              span {
                animation: xAxis-t 1s;
              }
            }
          }
          &:first-child {
            span {
              animation-delay: 0.1s;
            }
          }
          span {
            user-select: none;
            position: relative;
            top: -12px;
            display: inline-block;
            animation: bounce 5s ease infinite;
            font-size: 60px;
            @include md {
              font-size: 50px;
            }
            font-weight: 500;
            color: $light-main-hover;
            text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc, 0 5px 0 #ccc,
              0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent, 0 9px 0 transparent,
              0 10px 10px rgba(0, 0, 0, 0.4);
          }
        }
      }
      padding-right: 6px;
      padding-left: 6px;
      width: 43.5%;
      max-width: 700px;
      @include md {
        padding: 0;
        width: 100%;
        &:first-child {
          padding-bottom: 20px;
        }
      }
    }
  }
  &.headsets {
    > div {
      &:nth-child(4n + 1) {
        padding-left: 0;
        padding-right: 10px;
      }
      &:nth-child(4n + 2) {
        padding-left: 2px;
        padding-right: 6px;
      }
      &:nth-child(4n + 3) {
        padding-left: 6px;
        padding-right: 2px;
      }
      &:nth-child(4n) {
        padding-left: 10px;
        padding-right: 0;
      }
      padding: 0;
      &.normal-mode {
        width: 25%;
        .ant-card-body {
          margin: 18px;
          .ant-card-meta-detail {
            display: flex;
            min-height: 100px;
            flex-direction: column;
            .ant-card-meta-description {
              margin: auto;
            }
          }
        }
        @include lg {
          width: 33.333%;
          &:nth-child(3n + 1) {
            padding-left: 0;
            padding-right: 10px;
          }
          &:nth-child(3n + 2) {
            padding-left: 2px;
            padding-right: 2px;
          }
          &:nth-child(3n) {
            padding-left: 10px;
            padding-right: 0;
          }
        }
        @include sm {
          &:nth-child(2n + 1) {
            padding-left: 0;
            padding-right: 3px;
          }
          &:nth-child(2n) {
            padding-left: 3px;
            padding-right: 0px;
          }
          width: 50%;
        }
      }
      &.compare-mode {
        @include sm {
          padding: 0;
          &:first-child {
            padding-right: 4px;
          }
          &:last-child {
            padding-left: 4px;
          }
          .headset-card {
            .ant-card {
              &.selected {
                box-shadow: none;
              }
            }
          }
        }
        transition: width 1s ease-out;
        transition-delay: 300ms;
        width: 50%;
        .ant-card-body {
          margin: 0px;
        }
      }
    }
  }
}

.fade-headsets-enter {
  opacity: 0.01;
  &.fade-headsets-enter-active {
    opacity: 1;
    transition: opacity 700ms ease-in;
  }
}

.fade-headsets-leave {
  opacity: 1;
  &.fade-headsets-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }
}

.price-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 16px;
  > span {
    text-align: left;
    color: grey;
  }

}
