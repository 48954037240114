$breakpoint-xxs: 400px;
$breakpoint-xs: 480px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1600px;

$light-main: #001529;
$light-main-hover: #1890ff;

$dark-main: #1f1f1f;
$dark-main-hover: #503D4D;
$dark-main-link: #955A69;

$white-text: rgba(255, 255, 255, 0.85);