@import '../../../assets/scss/global.scss';
@import '../../../assets/scss/mixin.scss';

@include dark {
  .layout-404 {
    svg {
      path {
        &:nth-child(11) {
          fill: $dark-main-hover;
        }
      }
    }
    .ant-btn {
      border-color: $dark-main-hover;
      background-color: $dark-main-hover;
      &:hover {
        border-color: $dark-main-link;
        background-color: $dark-main-link;
      }
    }
  }
}
