@import "../../../assets/scss/global.scss";
@import "../../../assets/scss/mixin.scss";

.about {
  margin-left: 10%;
  margin-right: 10%;
  @include lg {
    margin-left: 2.5%;
    margin-right: 2.5%;
  }

  .divider {
    margin-top: 22px;
    margin-bottom: 22px;
  }
  .title {
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    padding: 0 24px;
  }

  p {
    text-align: justify;
    font-weight: 400;
    font-size: 16px;
    padding: 0 24px;
  }
}
